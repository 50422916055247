body {
  background-color: #FAFCFC !important;
}

.text-left {
  text-align: left;
}

.float-left {
  float: left;
}

.d-phone-block {
  display: none;
}

@media (max-width: 500px) {
  .d-phone-block {
    display: block;
  }
}

.w-lg-50 {
  width: 50% !important;
}

.text-center-n-lg {
  text-align: inherit;
}

@media (max-width: 991px) {
  .w-lg-50 {
    width: 100% !important;
  }
  .text-center-n-lg {
    text-align: center;
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: bold;
}

.mt-6 {
  margin-top: 4rem !important;
}
.mb-6 {
  margin-bottom: 4rem !important;
}

.status-default {
  padding: 3px 6px;
  border-radius: 4px;
  border: 1px solid;
}

.status-cancelled {
  @extend .status-default;
  color: $red;
  border-color: $red;
}

.status-info {
  @extend .status-default;
  color: $brand;
  border-color: $brand;
}

.p-font-size {
  font-size: 20px;
}
.no-padding {
  padding: 0!important;
}

.br-phone {
  display: none;
}
.br-lg {
  display: inherit;
}

@media (max-width: 991px) {
  .br-lg {
    display: none;
  }
  .p-font-size {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .br-phone {
    display: inherit;
  }
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}