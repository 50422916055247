@import "../../colors";

.simple-table {
  thead {
    @extend .background-blue-light;

    th {
      color: $brand;
      font-weight: 500;
      padding: 0.25rem 1rem;
    }
  }
  tbody {
    color: $brand;
    &:not(caption)>*>* {
      padding: .5rem 1rem!important;
      border-right: 1px solid $brand;
      &:last-of-type {
        border-right: 0;
      }
    }
  }
}

.table-titles {
  p {
    border-right: 1px solid $brand;
    margin-bottom: 0!important;
    padding-bottom: 1rem!important;
    font-weight: bold;
    &:first-of-type {
      padding-top: 1rem!important;
    }
  }
}
.table-values {
  p {
    &:first-of-type {
      padding-top: 1rem!important;
    }
  }
}
.table-entry {
  border-bottom: 1px solid $brand;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  margin-top: 3rem !important;
}