@import "../colors";

.contact-left-container {
  background: linear-gradient(216.62deg, #FFFFFF 21.93%, #D9E2F4 104.3%);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0 0 10px;
  padding: 1rem 2rem !important;
  color: $brand;
}

.contact-right-container {
  background: linear-gradient(180deg, #205DD3 0%, #2643A3 100%);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0 10px 10px 0;
  padding: 1.75rem 3.5rem !important;
}

.contact-info-container {
  margin-top: 3rem;
  margin-bottom: 3rem;

  a {
    color: $brand !important;
    display: block;
    margin-bottom: 1rem;

    &:hover {
      color: $brand-secondary !important;

      svg {
        color: $brand-secondary !important;
      }
    }

    svg {
      margin-top: -4px;
      margin-right: 1rem;
    }

  }


}

.contact-social-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  //position: absolute;
  //bottom: 1rem;
  //left: calc(50% - 2rem);
  //transform: translateY(-50%);
}

.contact-image {
  width: 50%;
}

.contact-right-container {
  label {
    color: $white !important;

    a {
      text-decoration: underline !important;
      color: $white !important;

      &:hover {
        color: $brand-secondary !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .contact-left-container {
    border-radius: 0;
    margin-top: 3rem !important;
  }
  .contact-right-container {
    padding: 1.75rem 2rem !important;
  }
}