@import "../../colors";

.service-row-container {
  display: flex;
  flex-direction: row;
  border: 1px solid $brand;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.75rem 2rem;
  margin-bottom: 2rem;
  align-items: center;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  h4, p {
    color: $brand;
  }


  &:hover {
    border-color: $brand-secondary;
  }

  .service-image-container {
    margin-right: 2rem;
    width: 70px;
    text-align: center;

    img {
      max-height: 70px;
    }
  }

  .service-text {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 991px) {
  .service-row-container {
    flex-direction: column;
    justify-content: center;

    .service-image-container {
      margin-bottom: 1rem;
      margin-right: 0;
    }

    .service-text {
      display: flex;

      h4 {
        text-align: center;
      }

      p {
        text-align: justify;
      }

    }
  }
}
