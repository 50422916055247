@import "../../colors";

.underline-bar {
  position: relative;
}

.underline-bar::before {
  position: absolute;
  bottom: -8px;
  left: calc(50% - 2.5rem);
  transform: translateY(-50%);
  content: "";
  width: 5rem;
  height: 2px;
  background-color: #FFC040;
}

.home-title {
  color: $white;
  line-height: 2.5rem;
}

.how-it-works-container {
  background-image: url("../../../images/new/homeServices/cum_functioneaza.png");
  margin-bottom: 7rem;

  .img-container {
    text-align: right;

    img {
      width: auto;
    }

    div {
      display: none;
    }
  }

  .content-container {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 100px;
  }

  .title-container {
    padding-top: 1rem;
    height: 100px;
  }

  .step-container {
    padding-top: 3rem;

    .step-cta-container {
      text-align: center;

      p {
        margin-bottom: 2rem;
      }

      button {
        margin: auto;
      }
    }

    .step-even {
      margin-right: 2rem;
      float: left;
    }

    .step {
      margin-bottom: 2rem;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      width: calc(50% - 1rem);
      height: 150px;

      div {
        margin-left: 10px;
      }
    }
  }

  .step-container-old {
    padding-top: 3rem;

    .step-cta-container {
      text-align: center;

      button {
        margin: auto;
      }
    }

    .step-even {
      margin-right: 2rem;
      float: left;
    }

    .step {
      margin-bottom: 2rem;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 16px;
      display: flex;
      flex-direction: row;
      width: calc(50% - 1rem);
      height: 150px;

      .number {
        font-size: 40px;
        color: #223B99;
        margin-top: -10px;
      }

      div {
        margin-left: 10px;
      }
    }
  }
}

.testimonials-container {
  margin-bottom: 1rem!important;
  min-height: 12rem;
  background-color: #353FBF;
  border-radius: 16px;
  padding: 2rem;
  margin-right: 1rem;

  .t-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      border-radius: 100%;
      width: 70px;
    }

    div {
      margin-left: 1rem;

      p:first-of-type {
        color: #505D68;
        margin-bottom: 0;
        font-weight: bold;
      }

      p:nth-of-type(2) {
        color: #A1AEB7;
        margin-bottom: 0;
      }
    }
  }
}

.download-app-container {
  background-image: url("../../../images/new/homeServices/descarca_aplicatia_zumzi.jpg");
  background-size: cover;
  background-position: center;
  padding-bottom: 10rem;

  .d-app-content {
    padding-top: 10rem;
  }

  .d-phone-mobile {
    display: none;
  }
}

.subscription-home-container {
  padding-top: 7rem !important;
  margin-bottom: 7rem;

  h1 {
    margin-bottom: 2rem;
  }

  & .subscription-home-container-left {
    padding-right: 3rem;

    h1 {
      margin-top: 5rem;
    }

    img {
      width: 100%;
    }

    p {
      margin-bottom: 3rem;
    }
  }

  & .subscription-home-container-right {
    padding-top: 7rem;
    padding-left: 3rem;


    p {
      margin-bottom: 8rem;
    }
  }
}

.service-top-container {
  margin-bottom: 8rem;

  .service-top-container-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .service-top-container-item {
    background: $white;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 3rem;
    text-align: center;
    height: 100%;

    h4 {
      margin-top: 1rem;
    }

    p {
      margin-bottom: 3rem;
      margin-top: 1rem;
    }

  }
}

.home-benefits-container {
  h4 {
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
}

.home-become-partner {
  margin-top: 4rem;
  background: #F4F5FA;
  padding-top: 4rem;
  padding-bottom: 5rem;

  h1 {
    color: $black;
  }

  .home-become-partner-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5rem;

    img {
      width: 100%;
    }
  }

  .home-become-partner-right {
    padding: 0 !important;

    .home-become-partner-right-first {
      padding-right: 2rem;
    }
  }

  .home-benefit-item {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;

    div:first-of-type {
      margin-right: 0.5rem;
    }
  }
}

.home-frequent-services-container {
  a {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1399px) {
  .how-it-works-container {

    .content-container {
      padding-left: 4rem;
    }

    .step-container {
      padding-top: 2rem;

      .step {
        width: 100%;
        height: 110px;
      }
    }

    .step-cta-container {
      margin-bottom: 2rem;

      p {
        display: none;
      }
    }
  }
  .service-top-container {
    .service-top-container-item {
      padding: 3rem 1rem;
    }
  }
}

@media (max-width: 1199px) {
  .how-it-works-container {
    .img-container {
      img {
        width: calc(317px + 50vw - 500px);
      }
    }
  }
}

@media (max-width: 992px) {
  .how-it-works-container {
    background-image: none;
    background-color: #F4F5FA;

    .img-container {
      text-align: center;

      img {
        width: 50%;
      }

      div {
        display: block;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }

    .content-container {
      padding-left: calc(var(--bs-gutter-x) * .5);;
      padding-right: calc(var(--bs-gutter-x) * .5);;
      padding-bottom: 2rem;

      .step-container {
        .step {
          padding: 16px 0;

          div {
            margin-left: 0;
          }
        }
      }
    }

    .title-container {
      display: none;
    }


    .step-container {
      .step {
        width: 100%;
        height: auto;
      }

      .step-even {
        float: none;
      }
    }
  }

  .download-app-container {
    background-image: none;
    background-color: #F4F5FA;
    padding-bottom: 0;

    .d-app-content {
      padding-top: 4rem;
      padding-bottom: 2rem;

      .d-download-links {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }
    }

    .d-phone-mobile {
      display: block;
      text-align: right;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;

      img {
        width: 100%;
      }
    }
  }

  .subscription-home-container {
    & .subscription-home-container-left {
      p {
        margin-bottom: 2rem;
      }

      img {
        width: 75%;
        margin: auto;
        display: block;
      }
    }

    & .subscription-home-container-right {
      padding-top: 2rem;
      padding-left: calc(var(--bs-gutter-x) * .5);

      h1 {
        margin-top: 2rem;
      }

      p {
        margin-bottom: 2rem;
      }

      img {
        max-width: 50%;
      }
    }
  }
  .home-benefits-container {
    h4 {
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    img {
      max-width: 75%;
    }
  }
  .testimonials-container {
    min-height: auto;
    margin-bottom: 2rem;
    margin-right: 0rem;
  }
  .home-frequent-services-container {
    h1 {
      text-align: left !important;
    }
  }
  .home-become-partner {
    .home-become-partner-left {
      margin-bottom: 2rem;

      img {
        width: 50%;
      }
    }
  }
}

@media (max-width: 576px) {
  .home-title {
    line-height: 1.75rem;
    font-size: 1.25rem;
  }

  .how-it-works-container {
    background-image: none;
    background-color: #F4F5FA;

    .img-container {
      text-align: center;

      img {
        width: 75%;
      }
    }
  }
}

.home-form {
  background: linear-gradient(270deg, #345AC6 0.18%, #2541A1 100.18%);
  border-radius: 16px;
  padding: 4rem;
}

@media (max-width: 991px) {
  .home-form {
    border-radius: 0;
    padding: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.how-it-works-left-img{
  background-image: url('../../../images/new/home/zumzi_work_2.png');
  background-size: cover;
  border-bottom-right-radius: 16px;
}

.how-it-works-right-img {
  background-image: url('../../../images/new/home/zumzi_work_1.png');
  background-size: cover;
  background-position: bottom;
  border-bottom-left-radius: 16px;
}

.how-it-works-center {
  padding-left: 41px;
  padding-right: 41px;
}
@media (max-width: 991px) {
  .how-it-works-center {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.locations {
  background-size: cover;
  background-position: bottom;
  height: 484px;
  margin-right: 56px;
  margin-left: -16px;
  border-radius: 16px!important;
}

.locations-first-1 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_1.png');
}

.locations-first-2 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_2.png');
}

.locations-first-3 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_3.png');
}

.locations-first-4 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_4.png');
}

.locations-first-5 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_5.png');
}

.locations-second-1 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_6.png');
}
.locations-second-2 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_7.png');
}

.locations-second-3 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_8.png');
}

.locations-second-4 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_9.png');
}

.locations-second-5 {
  background-image: url('../../../images/new/home/location/zumzi_locatie_10.png');
}

.locations-first-row {
  margin-bottom: 2rem;
  padding-left: 0!important;
  padding-right: 0!important;
  margin-top: -2rem!important;
}
.locations-second-row {
  margin-bottom: 10rem;
  padding-left: 0!important;
  padding-right: 0!important;
}
.slick-slider-gallery {
  padding-left: 0!important;
  padding-right: 0!important;
  .slick-prev, .slick-next {
    display: none!important;
  }
}
@media (max-width: 991px) {
  .locations {
    height: 280px;
    margin-right: 32px;
    margin-left: -16px;
  }
  .locations-first-row {
    margin-bottom: 8px!important;
  }
  .locations-second-row {
    margin-bottom: 64px;
  }
}
.banner-form {
  margin-left: -300px;
  padding-top: 48px;
  margin-bottom: -48px;
}

.banner-img {
  background-image: url('../../../images/new/home/zumz_acasa.png');
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.banner-container {
  margin-top: 8rem;
  margin-bottom: 10rem;
}
@media (max-width: 991px) {
  .banner-form {
    margin-left: 0;
    padding-top: 0;
    margin-bottom: 0;
  }
  .banner-container {
    margin-top: 7rem;
    margin-bottom: 5rem;
  }
}
.benefits-image {
  width: 100%;
}
@media (max-width: 991px) {
  .benefits-image {
    width: 50%;
  }
}
@media (max-width: 500px) {
  .benefits-image {
    width: 100%;
  }
}


