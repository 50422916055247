@import "../colors";

.footer {
  background-color: #2643A3;

  padding-top: 4rem;
  padding-bottom: 4rem;
}

.footer-title {
  color: $white;
  display: block;
  position: relative;
  margin-bottom: 2rem;
}


.footer-link {
  color: $white;
  display: block;
  margin-bottom: 0.75rem;
}

.footer-contact-link {
  color: $white;
  margin-bottom: 0.75rem;
  display: block;

  span {
    margin-left: 1rem;
  }
}

.footer-social-link {
  margin-right: 0.5rem;
}

.footer-link, .footer-contact-link, .footer-social-link {
  &:hover, &:focus, &:active {
    color: $brand-secondary !important;

    svg {
      color: $brand-secondary !important;
    }
  }
}

.footer-socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
}