@import "../../colors";

.breadcrumb-item {
  a {
    color: $brand!important;
    &:hover {
      color: $brand-secondary!important;
    }
  }
}
@media (max-width: 500px) {
  .breadcrumb-item {
    font-size: 14px;
  }
  .breadcrumb-item+.breadcrumb-item {
    padding-left: .25rem!important;
  }
  .breadcrumb-item+.breadcrumb-item::before {
    padding-right: .25rem!important;
  }
}