.nav-link {
  font-size: 20px;
  color: $black !important;
}

.navbar {
  display: block !important;
}

.nav-link.active {
  color: $black !important;
  border-bottom: 1px solid $black;
  padding-bottom: 2px;
}

.nav-link-dropdown {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-left: 0.5rem;
  margin-right: 0 !important;
  float: left;
}

.dropdown-toggle-split {
  margin-right: 0.5rem;
  background: transparent !important;
  border: 0 !important;
}

.navbar-icons {
  a {
    padding-bottom: 0.25rem;
    border-bottom: 1px solid transparent;
  }

  a:hover {
    border-bottom: 1px solid $brand;
  }
}

.navbar-links {
  a {
    margin-right: 1rem;
    border-bottom: 2px solid transparent;
  }

  a:hover {
    border-bottom: 2px solid $brand;
  }
}

.nav-link-hover {
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid $brand;
  }
}

.nav-link-border {
  border: 1px solid $black;
  border-radius: 70px;
  padding: 8px 16px !important;

  &:hover {
    border: 1px solid $brand;
    color: $brand !important;
  }
}


.mobile-navbar {
  display: none !important;
}

.desktop-navbar {
  display: flex !important;
}

@media (max-width: 991px) {
  .desktop-navbar {
    display: none !important;
  }
  .mobile-navbar {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 16px !important;

  }
}

.burger-container {
  padding: 0.5rem;
  color: $black;
}

.desktop-navbar, .sidebar {
  .btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
  }

  .btn-check:focus + .btn-primary, .btn-primary:focus {
    box-shadow: none !important;
  }
}

.dropdown-menu-inner:hover {
  background-color: opacify($brand, 0.1);
  border-bottom: 1px solid transparent !important;
}

