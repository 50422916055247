@import "colors";
.sidebar {
  z-index: 1053;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 250px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background: linear-gradient(227.63deg, #FFFFFF 2.05%, #D9E2F4 102.97%);
  border-radius: 0px 30px 30px 0px;
}
@media (max-height: 650px) {
  .sidebar {
    min-height: calc(650px - 77px) !important;
  }
}
.sidebar-wrapper{
  @extend .background-grey-opacity;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  z-index: 9998;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}
.sidebar {
  .nav-link {
    color: $blue !important;
    font-size: 20px;
    padding: 0.5rem 2rem;
  }
  .nav-link.active, .nav-link:hover {
    // $blue with opacity
    @extend .background-blue-light;
    background-color: rgba(3, 103, 191, 0.2)!important;;
    border: none;
  }
}
.headerContainer {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.closeContainer {
  margin-bottom: 5rem;
  text-align: right;
  padding: 1rem 1rem 0 0;
}
.iconContainer {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  position: absolute;
  bottom: 1rem;
}