@import "../../colors";

.address-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  align-items: center;
}
.address-details-text {
  font-size: 16px;
  opacity: 0.5;
  margin-bottom: 0;
}
.property-container {
  //border: 1px solid $grey;
  //border-radius: 8px;
  //padding: 16px;
  margin-bottom: 2rem;
  label {
    font-weight: 100 !important;
  }
}
@media (max-width: 991px) {
  .address-item {
    flex-direction: column;
    padding-bottom: 1rem;
    align-items: flex-start;
  }
  .address-item-buttons {
    margin-top: 0.25rem;
  }
  .property-container {
    border: none;
    border-radius: 8px;
    padding: 16px 0;
  }
}