@import "../../colors";

.w-33 {
  width: 33.33%;
}
.my-order-item-container {
  margin-bottom: 2rem;
}

.my-orders-image-container {
  background: #9EC0EC;
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0!important;

  div:first-of-type {
    padding: 2rem;
  }
  h6 {
    color: $brand;
    margin-top: 1rem;
  }
  img {
    width: 33.33%;
    min-width: 50px;
  }
}
.my-orders-bottom-container {
  background: $brand;
  border-radius: 0px 0px 0px 10px;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  color: $white;
  font-size: 14px;
  cursor: pointer;
  &:hover, &:focus {
    opacity: 0.6;
  }

  &.canceled {
    background: $red;
    cursor: default;
    &:hover, &:focus {
      opacity: 1;
    }
  }

  @media (max-width: 991px) {
    & {
      border-radius: 0px 0px 10px 10px;
    }
  }
}
.my-orders-details-container {
  background: #DFE7F6;
  border-radius: 0px 10px 10px 0px;
  padding: 0!important;

  div:first-of-type {
    padding: 1.5rem 0.75rem;
  }

  p {
    color: $brand;
    &:last-of-type {
      margin-bottom: 0!important;
    }
  }
}
@media (max-width: 991px) {
  .my-orders-image-container {
    border-radius: 10px 10px 0px 0px;
    img {
      width: 25%;
    }
  }
  .my-orders-details-container {
    border-radius: 0px 0px 10px 10px;
  }
}