@import "../colors";

.faq-container {
  margin-bottom: 5rem;

  .nav-link {
    padding: 0.75rem 0 !important;
    color: $brand !important;
  }

  .nav-link.active {
    padding: 0.75rem 0 !important;
    border: none;
    color: $brand-secondary !important;
  }

  .nav-link-icon {
    margin-right: 0.75rem;
    margin-top: -0.25rem;
  }

  .nav-link:hover {
    cursor: pointer;
    color: $brand-secondary !important;
  }

  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background: none !important;
  }

  .section-content {
    margin-bottom: 3rem;
  }

  .section-title {
    margin-bottom: 1rem;
  }

  .nav-titles {
    flex-direction: column;
    position: sticky;
    top: 0;
    border-right: 2px solid $black;
    z-index: 0;
  }

  @media (max-width: 767px) {
    .nav-titles {
      position: relative;
      border-right: 0;
      padding-bottom: 1rem;
      margin-bottom: 2rem !important;
      border-bottom: 2px solid $white;

      .nav-link {
        margin-right: 1rem;
        font-size: 1.25rem;
      }
    }
  }

}
