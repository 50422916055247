@import "../colors";

.partner-container {
  p, h4 {
    color: $black;
  }

  img {
    width: 100%;
  }

  ul {
    color: $black;
    margin-left: -12px;

    li {
      margin-bottom: 12px;
    }
  }
}
