.download-app-image {
  width: 170px;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.download-app-image-first {
  margin-right: 2rem;
}

@media (max-width: 991px) {
  .download-app-image-first {
    margin-right: 1rem;
  }
  .download-app-image {
    width: 8.5rem;
  }
}