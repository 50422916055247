.voucher-referral-container {
  display: block;
  text-align: left;
}

@media (max-width: 991px) {
  .voucher-referral-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
