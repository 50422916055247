@import "../../colors";

.service-description-container {
  border: 2px solid $brand-secondary;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 1.25rem 1.5rem;

  p, span {
    color: $brand;
    font-weight: bold;
  }
}

.service-price {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  color: $brand;
}

.service-old-price {
  color: $brand;
  text-decoration: line-through;
  font-size: 14px;
  margin-bottom: 0;
}

.service-discount-price {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  color: $brand-secondary;
}

.service-summary-container {
  padding-top: 1rem;

  border-bottom-width: 2px;
  border-style: solid;
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: $brand-secondary;
}

.service-summary-price-container {
  text-align: right;
  padding-top: 0.5rem;
  margin-bottom: 2rem;

  p {
    font-size: 14px;
    opacity: 0.7;
    color: $brand;
    text-decoration: line-through;
    margin-bottom: 0;
  }

  h6 {
    color: $brand;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    color: $brand;
    opacity: 0.7;
  }
}

.see-details-text {
  margin-top: 8px;
  cursor: pointer;
  color: rgb(8, 48, 107);
  text-decoration: underline;
}

.cards-container {
  padding-top: 0.5rem;

  border-bottom-width: 0px;
  border-style: solid;
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: $brand-secondary;
}