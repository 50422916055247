.guest-title {
  font-weight: 800;
  line-height: 50px;
  font-size: 3.5rem;
}

.guest-subtitle {
  opacity: 0.6;
}

.guest-background {
  background-image: url("../../images/new/homeServices/zumzi_fundal.jpg");
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;

  .guest-center-container {
    img {
      max-width: 50%;
    }
  }
}

.guest-header-buttons {
  margin-bottom: 5rem;

  div {
    text-align: center;
  }

  a {
    display: initial;
  }
}

.guest-more-details-container {
  display: block;
}

@media (max-width: 1200px) {
  .guest-header-buttons {
    margin-bottom: 3rem;

    div:first-of-type {
      text-align: center;
    }

    div:nth-of-type(2) {
      text-align: center;
    }
  }
}

@media (max-width: 576px) {
  .guest-title {
    font-size: 3rem;
  }
  .guest-background {
    .guest-center-container {
      img {
        width: 50%;
      }
    }
  }
  .guest-more-details-container {
    display: none;
  }
}
