@import "../colors";

.vertical-center {
  min-height: calc(100vh - 100px - 4rem);
  //margin-top: calc(-5rem - 25px);
  display: flex;
  align-items: center;
}

.auth-container-logo {
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-align: center;

  img {
    width: 100px;
  }
}

.auth-container-form {
  background: $white;
  border-radius: 10px;
  text-align: center;
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 14px 12px 0 rgba(0, 0, 0, 0.2), 0 20px 38px 0 rgba(0, 0, 0, 0.22);

  p {
    color: $grey-dark;
    margin-bottom: 2rem;
  }

  &.fixed-container {
    height: calc(100vh - 180px - 2rem);
    position: relative;

    & .auth-container-inner {
      max-height: 100%;
      min-height: 100%;

      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.auth-extra-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .auth-container-form {
    padding: 1.5rem;

    &.fixed-container {
      height: calc(100vh - 80px - 4rem);
    }
  }
  .auth-extra-links {
    p {
      display: none;
    }

    a {
      margin-top: -0.5rem;
      margin-bottom: 2rem;
    }
  }

  .auth-container-logo {
    padding-bottom: 1rem;
    padding-top: 1rem;
    text-align: center;

    img {
      width: 80px;
    }
  }

}
@media (orientation: portrait) {
  .vertical-center {
    min-height: calc(100vh - 100px - 1rem);
  }
}