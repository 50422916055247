@import "../colors";

.account-background {
  background: url("../../images/new/zumzi_fundal.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
}

.navbar-account {
  position: fixed !important;
  width: 100% !important;
  top: 0;

  .nav-link {
    color: $white !important;

    &:hover {
      border-bottom: 1px solid $white;
    }
  }
}

.account-container {
  margin-left: calc(250px + 25px);
  margin-right: 25px;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-top: 6rem;
}

.account-container-left {
  margin-right: 20px;
  border-radius: 20px;
  padding: 25px 40px;
  background: linear-gradient(231.27deg, #FFFFFF 30.08%, #D9E2F4 111.55%);
  width: calc(100% - 330px - 20px);
  float: left;
  min-height: calc(100vh - 50px - 84px);
}

.account-container-right {
  background: linear-gradient(231.27deg, #FFFFFF 30.08%, #D9E2F4 111.55%);
  border-radius: 20px;
  width: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 1rem 2rem;
  color: $brand;
}

@media (max-width: 991px) {
  .account-container {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 1rem;
  }
  .account-container-left {
    padding-top: 0.5rem;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 0;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .account-container-right {
    display: none;
  }
  .account-container-left {
    width: 100%;
  }
}

.account-title {
  font-weight: 400;
  margin-bottom: 1rem;
}

.flex-item-in-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.flex-item-in-list-container {
  p {
    margin-bottom: 0;
  }

  span {
    opacity: 0.5;
  }
}

@media (max-width: 991px) {
  .flex-item-in-list {
    flex-direction: column;
    align-items: flex-start;
  }
}

.account-medium-container {
  max-width: 500px;
}