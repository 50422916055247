@import "../../colors";

.edit-button-container {
  background: $blue-light;
  padding: 2px 4px;
  cursor: pointer;

  svg {
    color: $brand;
  }
}
