@import "../colors";

.bar-title {
  display: none;
  width: 100%;
  height: 2px;
  background-color: $brand-secondary-dark;
}
.header-title-section {
  display: block;
}
.header-title {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
  color: $white;
  padding: 0 1rem;
}
.header-subtitle {
  color: $white;
  text-align: center;
  padding: 0 1rem;
  margin-top: -4rem;
  margin-bottom: 5rem;
  font-weight: 100;
}
@media (max-width: 500px) {
  .bar-title {
    display: block;
  }
  .header-title {
    font-size: 1.25rem;
    font-weight: 700;
    width: 100%;
    white-space: nowrap;
    margin-top: 0;
    margin-bottom: 3rem;
    padding: 3rem .75rem 0 .75rem;
  }
  .header-title-with-subtitle {
    font-size: 2rem;
  }
  .header-title-section {
    display: flex;
    align-items: center;
  }
  .header-subtitle {
    margin-top: -2rem;
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 100;
  }
}
