@import "../../colors";

.service-description-default {
  h4, p, ul, div {
    position: relative;
  }

  h4:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.5rem;
    width: 4rem;
    height: 2px;
    background-color: $brand-secondary;
  }
}

.service-description {
  ul {
    margin-left: -0.75rem !important;

    li {
      margin-top: 0.25rem;
    }
  }
}

.service-description-dark {
  h4, p, ul, div {
    color: $brand;
  }
}