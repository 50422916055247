@import "../../colors";

.header-container {
  background: url(../../../images/new/zumzi_funal_dreapta_sus.png);
  border-radius: 20px;
  width: 100%;
  height: 175px;
  background-repeat: no-repeat;
  background-size: cover;
  clear: both;
  margin-bottom: 5rem;

  .left {
    text-align: right;
    margin-top: 3rem;
  }
  p {
    color: $white;
    padding: 3rem 2rem 1rem 2rem;
  }
}
.custom-option:hover {
  @extend .background-blue-light;
}
@media (max-width: 991px) {
  .header-container {
    height: auto;
    background: none;
    margin-bottom: 2rem;
    .left {
      text-align: center;
      img {
        max-width: 500px;
        width: 100%;
      }
    }
    p {
      display: none;
    }
  }

}