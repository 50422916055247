@import "../../colors";


.react-calendar  {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  .react-calendar__navigation {
    text-align: center;
    margin-bottom: 1rem;
  }
  .react-calendar__month-view__weekdays {
    margin-bottom: 1rem;
    text-align: center;
  }
  .react-calendar__navigation__label, .react-calendar__navigation__arrow {
    background-color: $white;
  }
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: none!important;
    }
  }
}
.date {
  border-radius: 9px;
  background: $white;
  margin-bottom: 1rem;
}
.date-active {
  background: linear-gradient(216.25deg, #F9E63B -6.49%, #FAAC38 76.68%);
}
.calendar-container {
  max-width: 350px;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
  border-radius: 22px;
  margin-bottom: 1.5rem;
}
.button-hour {
  background: $grey-light;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: $brand;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  width: calc(33% - 0.67rem);
  margin-bottom: 0.75rem;
  &:nth-of-type(3),&:nth-of-type(6),&:nth-of-type(9) {
    margin-right: 0;
  }
}
.button-hour-selected {
  background: $brand-secondary!important;
}