button {
  border: none;
}

$borderRadiusButton: 20px;
$paddingButton: 8px 16px;
$widthButton: 150px;
$fontSmallButtons: 14px;

.button-gradient-primary {
  padding: 12px 24px;

  background: linear-gradient(270deg, #345AC6 0.18%, #2541A1 100.18%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 70px;
  //border: 1px solid #F3DB57;
  text-align: center;
  color: $white !important;
  font-size: 18px;

  &:focus {
    box-shadow: 0 4px 4px 1px rgba(7, 46, 104, 0.25) !important;

  }

  &:hover {
    color: $brand-secondary !important;
  }
}

.button-cta-sec {
  padding: 12px 24px;
  background: linear-gradient(90deg, #F3DB57 -0.2%, #EEBE42 99.8%);
  border-radius: 70px;
  box-shadow: 0 4px 4px 1px rgba(7, 46, 104, 0.25);
  text-align: center;
  cursor: pointer;
  color: $black;

  &:hover {
    color: $white;
  }
}

$borderRadius: 2px;
.button-medium {
  border-radius: $borderRadius;
  padding: 8px 16px;
  font-size: $fontSmallButtons;
  cursor: pointer;
}


$paddingSmallButton: 2px 4px;
.button-small {
  border-radius: $borderRadius;
  padding: $paddingSmallButton;
  font-size: $fontSmallButtons;
  cursor: pointer;
}

.button-small-current {
  background-color: $blue-light;
  color: $brand;
  cursor: pointer;

  &:hover {
    color: $brand-secondary;
  }
}

.button-small-current-selected {
  background-color: $brand-secondary;
  color: $white;
  cursor: pointer;
}

.button-small-edit {
  color: $brand;
  cursor: pointer;
}

.button-small-remove {
  color: $red;
  cursor: pointer;
}

.button-small-processing {
  background-color: $brand;
  color: $white;
  cursor: pointer;
}

.button-small-cta {
  background-color: $brand-secondary;
  color: $brand;
  height: fit-content;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.button-small-save {
  background-color: $brand-secondary;
  color: $brand;
  cursor: pointer;

  &:hover {
    color: $white;
  }
}

.btn-primary {
  border: none !important;
}

.button-add {
  background: $white;
  box-shadow: 0 2px 4px rgba(51, 51, 51, 0.25);
  border-radius: 5px;
  text-align: center;
  width: 250px;
  padding: 0px 16px;
  color: $grey-dark;
  display: block;
  cursor: pointer;
}

@media (max-width: 991px) {
  .button-add {
    width: 100%;
  }
}

.button-back {
  padding: 8px 16px;
  background: none;
  float: left;
  border: 2px solid transparent;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    color: $brand-secondary;
  }
}

.button-big {
  padding-left: 4rem;
  padding-right: 4rem;
  display: inline !important;
}
@media (max-width: 500px) {
  .button-big {
    display: block !important;
  }
}
