@import "../../colors";

.delete-button-container {
  background: $blue-light;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    opacity: 0.5;
  }
}
