@import "../../colors";

.service-container {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    width: 300px;
    height: 228px;
  }

  h4 {
    margin-bottom: 1rem;
    margin-top: -1rem;
  }

  p {
    margin-bottom: 2rem;
  }
}

@media (max-width: 575px) {
  .service-container {
  }
}