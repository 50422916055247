@import "../../colors";

.padding-left-icon {
  padding-right: 2rem !important;
}

.autocomplete-dropdown-container {
  position: absolute;
  background-color: $white;
  top: 2.5rem;
  left: 0;
  width: 100%;

  .suggestion-item-container {
    border-bottom: 1px solid $grey;
  }

  .loading {
    padding: .5rem 0.5rem;
    font-size: 14px;
  }

  .suggestion-item, .suggestion-item-active {
    padding: .5rem 0.5rem;
    border-bottom: 1px solid $grey;
    border-left: 1px solid $grey;
    border-right: 1px solid $grey;

    cursor: pointer;

    &:last-of-type {
      border-bottom: none;
    }

    div {
      font-size: 14px;
      margin-bottom: 0 !important;

      white-space: nowrap;
      width: calc(16rem - 1rem);
      overflow: hidden;
      text-overflow: clip;
    }
  }

  .suggestion-item {
    background-color: $white !important;
  }

  .suggestion-item-active {
    @extend .background-blue-light;
  }
}