$brand: #08306B;
$brand-derivate: #041E41;
$brand-light: #233C9A;

$brand-secondary: #FABA0B;
$brand-secondary-derivate: #F9E63B;
$brand-secondary-dark: #ee7814;

$grey: #C4C4C4;
$grey-light: #FAFAFA;
$grey-dark: #575757;
$blue: #0367BF;
$blue-light: #ECF2FA;
$white: #FFF;
$black: #000;

$green: #2DA771;
$red: #FF2A23;
$yellow: $brand-secondary-derivate;

//$blue: #86c6fb;
//$indigo: #444054;
//$purple: #83588a;
//$pink: #f65ca2;
//$red: #d92550;
//$orange: #fd7e14;
//$yellow: #f7b924;
//$green: #3ac47d;
//$teal: #16E0BD;
//$cyan: #30b1ff;
//$blue-light: #eeeeee;

//$primary: $brand;
//$secondary: $gray-600;
//$success: $green;
//$info: $cyan;
//$warning: $yellow;
//$danger: $red;
//$light: $blue-light;
//$dark: $gray-800;
//$focus: $indigo;
//$alt: $brand-secondary;

.color-white {
  color: $white !important;
}

.color-brand {
  color: $brand !important;
}

.color-brand-secondary {
  color: $brand-secondary !important;
}

.color-red {
  color: $red !important;
}

.color-blue {
  color: $blue;
}

.color-grey-dark {
  color: $grey-dark;
}

.color-green {
  color: $green;
}

.color-black {
  color: $black;
}

.color-grey-text {
  color: #626262;
}

hr {
  background-color: $brand !important;
}

li::marker {
  color: $brand-secondary-dark;
}

.background-brand-secondary {
  background-color: $brand-secondary !important;
}

.background-brand-secondary-dark {
  background-color: $brand-secondary-dark !important;
}

.background-grey-opacity {
  background-color: rgba(196, 196, 196, 0.5);
}

.background-blue-light {
  background-color: rgba(3, 103, 191, 0.2) !important;
}

.color-hover-brand-secondary:hover {
  color: $brand-secondary !important;
}