@import "../../colors";

.file-container {
  position: relative;
  margin-right: 1rem;
  margin-top: 1rem;
  img, video {
    height: 100px;
  }
}

.button-close {
  position: absolute;
  right: 0;
  background: none;
  padding: 0;
  @extend .background-blue-light;
}