@import "../../colors";
@import "slick";


.slick-slider {
  .slick-prev, .slick-next {
    top: inherit;
    bottom: -5rem !important;
    background: #E5E5E5;
    color: #212121;
  }

  .slick-prev {
    left: calc(50% - 42.5px);
  }

  .slick-next {
    left: calc(50% + 2.5px);
  }

  .slick-prev::before, .slick-next::before {
    margin-left: 0 !important;
  }

  .slick-center {
    transform: none;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .slick-slider {
    .slick-prev, .slick-next {
    }
  }

  .slick-slider-full {
    .slick-prev, .slick-next {
      bottom: -3rem !important;
    }

    .slick-slide {
      margin-left: 20px;
    }

    .slick-list {
      padding-left: calc(10%) !important;
    }

    .slick-track {
      align-items: flex-end;
    }

  }
}

@media (min-width: 991px) {
  .slick-slider-top-right-buttons {
    .slick-prev, .slick-next {
      top: -2.5rem;
    }

    .slick-prev {
      left: calc(100% - 42.5px - 3.5rem);
    }

    .slick-next {
      left: calc(100% + 2.5px - 3.5rem);
    }
  }
}
