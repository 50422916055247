@import "../colors";

.cookie-banner {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: $brand-derivate;
  color: $white;
  padding: 1.5rem 2rem;
  z-index: 1031;

  .cookie-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 991px) {
    .cookie-banner-container {
      flex-direction: column;
      button {
        margin-top: 1rem;
      }
    }
  }
}