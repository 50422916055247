@import "../../colors";

.service-item-column-container {
  padding: 24px;
  background: #F4F5FA;
  border-radius: 10px;
  height: 100%;

  h4 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
    min-height: 10rem;
  }

  .item-image {
    border: 1px solid $brand-secondary-derivate;
    border-radius: 100%;
    padding: 1rem;
    width: 96px;
    height: 96px;
    background-color: $brand-light;

    .fill-white path {
      fill: white;
    }

    .stroke-white path {
      stroke: white;
    }
  }
}

.price-old-value {
  position: relative;
  font-weight: 100;
  margin-right: 4px;
  font-size: 18px;
}

.price-old-value:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: #EE7814;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

@media (max-width: 767px) {
  .service-item-column-container {
  }
}