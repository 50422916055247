@import "../colors";

.bg-color-sec {
  background-color: $brand-secondary;
}

.terms-content {
  padding: 0 2rem !important;

  p {
    color: $black !important;
  }
}

.terms-content ul, .terms-content p {
  font-size: 1rem;
}

.terms-ul {
  margin-left: 20px;
}

.terms-ul li {
  color: $black !important;
}

.terms-ul li:first-of-type {
  margin-top: 1rem !important;
}

.terms-content ol li {
  color: $brand-secondary;
  font-size: 1rem;
  padding-bottom: 1rem;
  line-height: 1.5rem;
}

.terms-content ol li {
  color: $brand-secondary;
  padding-bottom: 1rem;
  line-height: 1.5rem;
}


.terms-content ol p {
  color: $black;
}

.terms-content ol p:first-of-type {
  margin-top: 1rem;
}

.policy-ul * {
  color: $black !important;
}